export const getSubtotalLabel = (region) => {
  const vatRegions = [
    "dk",
    "de",
    "se",
    "fr",
    "be",
    "at",
    "bg",
    "cy",
    "cz",
    "ee",
    "gr",
    "es",
    "fi",
    "hr",
    "hu",
    "ie",
    "it",
    "lt",
    "lu",
    "lv",
    "mt",
    "nl",
    "pl",
    "pt",
    "ro",
    "si",
    "sk",
  ]

  const vatAndDutiesRegions = ["ch", "no", "gb"]
  const taxAndDutiesRegions = ["kr", "jp"]
  const localTaxesRegions = ["au", "nz", "ca", "row", "mo", "sa", "ae"]
  const importDutiesRegions = ["us"]

  if (vatRegions.includes(region)) {
    return "Subtotal (incl. VAT)"
  } else if (vatAndDutiesRegions.includes(region)) {
    return "Subtotal (incl. VAT and import duties)"
  } else if (taxAndDutiesRegions.includes(region)) {
    return "Subtotal (incl. tax and import duties)"
  } else if (localTaxesRegions.includes(region)) {
    return "Subtotal (local taxes and duties may apply)"
  } else if (importDutiesRegions.includes(region)) {
    return "Subtotal (incl. import duties)"
  } else {
    return "Subtotal (local taxes and duties may apply)"
  }
}
